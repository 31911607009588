/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@mui/material";
import { Vocabulary } from "../../Utils/vocabulary";

export default function QuestionnaireDone() {
  return (
    <div
      style={{
        color: "#647273",
        margin: 50,
      }}
    >
      <h1 style={{ margin: 30 }}>Revenim curând!</h1>
      <h2 style={{ margin: 30, fontWeight: "normal" }}>
        Momentan lucrăm la îmbunătățirea platformei și nu putem efectua
        achiziții.{" "}
      </h2>
      <h2 style={{ margin: 30, fontWeight: "normal" }}>
        Mulțumim pentru înțelegere și te așteptăm să revii!
      </h2>
      <a
        href="https://www.yzzy.ro/"
        style={{ textDecoration: "none", color: "white" }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "#358800", margin: "50px" }}
        >
          {Vocabulary.homePage}
        </Button>
      </a>
      <div
        style={{
          textAlign: "left",
        }}
      >
        <p>
          <a
            href="https://www.yzzy.ro/termeni-si-conditii/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#647273" }}
          >
            Termeni și condiții
          </a>
        </p>
        <p>
          <a
            href="https://www.yzzy.ro/contact/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#647273" }}
          >
            Contactează-ne
          </a>
        </p>
      </div>
    </div>
  );
}
